import {HStack, StackDivider, Text} from "@chakra-ui/react";
import {useAuthStore} from "../stores/auth";
import {Link as RouteLink} from "react-router-dom";
import * as React from "react";

type NavLinkProps = { text: string };
const NavLink = ({text}: NavLinkProps) => (
    <Text fontSize="xl">{text}</Text>
);

export function NavBar() {
    const [loggedIn, uuid, role] = useAuthStore((s) => [s.isLoggedIn(), s.uuid, s.role]);

    return (
        loggedIn ?
            <HStack mb={6} spacing={3} divider={<StackDivider/>} as="nav">
                <RouteLink to="/"><NavLink text="Home"/></RouteLink>
                {role !== "spy" ?
                    <RouteLink to="/agents"><NavLink text="Agents"/></RouteLink>
                    : null}
                <RouteLink to={"/agents/" + uuid}><NavLink text="My Profile"/></RouteLink>
                <RouteLink to="/logout"><NavLink text="Logout"/></RouteLink>
            </HStack>
            :
            null
    );
}