import {useQuery} from "@tanstack/react-query";
import {lastLoginQuery} from "../api/queries";
import {HTTPError} from "ky";
import {GICELogout} from "../pages/LogoutPage";
import {Text} from "@chakra-ui/react";
import React from "react";

// Example of fetching data from the server as a component
export function LastLogin({userid}: { userid: number }) {
    const data = useQuery(lastLoginQuery(userid));

    let status = "Loading...";

    if (data.status === "success") {
        const date = new Date(data.data.lastLogin * 1000);
        status = `Last logged in on ${date.toLocaleString(
            undefined,
            {
                hour12: false,
            }
        )}`;
    } else if (data.status === "error") {
        status = "Fetch error";

        if (data.error instanceof HTTPError) {
            if (data.error.response.status === 401) {
                return <GICELogout/>;
            }
        }
    }

    return <Text fontWeight={600} color={'gray.500'} mb={4}>
        {status}
    </Text>
}
