import {FormControl, Tag, TagLabel, TagRightIcon, WrapItem} from "@chakra-ui/react";
import {IoIosRemoveCircleOutline} from "react-icons/io";
import {AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList} from "@choc-ui/chakra-autocomplete";

export function EditableTagsComponent({isEditing, tags, setTags}: {
    isEditing: boolean,
    tags: string[],
    setTags: (id: (prevState: any) => any[]) => void
}) {
    const addTag = (toAdd: string) => {
        if (toAdd === "") {
            return
        }
        setTags((prevState) => [...new Set([...prevState, toAdd])])
    }

    const removeTag = (toRemove: string) => {
        setTags((prevState) =>
            prevState.filter((tag: string) => tag !== toRemove)
        );
    }
    const allTags = ["Recon","Industry", "Director", "BFL", "FC", "WH", "Diplos", "Welp", "Scouts", "Newbean", "Market Fuckery", "Capital", "Coord", "Insurgent Squad"]

    return (
        <>
            {isEditing ? (
                    tags.map((tag, index) => (
                        <WrapItem key={index}>
                            <Tag size="md" variant="subtle" colorScheme="whatsapp" borderRadius="sm" fontSize="sm">
                                <TagLabel>{tag}</TagLabel>
                                <TagRightIcon boxSize='14px' color="white" onClick={() => removeTag(tag)}
                                              as={IoIosRemoveCircleOutline}/>
                            </Tag>
                        </WrapItem>
                    ))) :
                tags.map((tag, index) => (
                    <WrapItem key={index}>
                        <Tag size="md" variant="subtle" colorScheme="whatsapp" borderRadius="sm"
                             fontSize="sm">{tag}</Tag>
                    </WrapItem>
                ))
            }

            {isEditing ? (
            <WrapItem key="addtags2">
            <FormControl w="60">
                <AutoComplete multiple={true} restoreOnBlurIfEmpty={false} listAllValuesOnFocus={true} openOnFocus={true} onSelectOption={(event) =>  { addTag(event.item.value); }} >
                    <AutoCompleteInput variant="filled" placeholder="Add tag"/>
                    <AutoCompleteList>
                        {allTags.map((tag, cid) => (
                            <AutoCompleteItem
                                key={`option-${cid}`}
                                value={tag}
                                textTransform="capitalize"
                            >
                                {tag}
                            </AutoCompleteItem>
                        ))}
                    </AutoCompleteList>
                </AutoComplete>
            </FormControl>
            </WrapItem>
            ) : null}
        </>
    )
}