'use client'

import {
    Heading,
    Box,
    Center,
    Text,
    Stack,
    Badge,
    useColorModeValue,
    Flex,
    Icon,
    StackDivider,
    StatLabel,
    StatNumber,
    Stat,
    Wrap,
    WrapItem,
    Button,
    IconButton, Input, Select,
} from '@chakra-ui/react'
import React, {ReactElement, useState} from "react";
import {
    BiTimeFive,
    BiRefresh,
} from "react-icons/bi";
import {
    AiOutlineUserAdd,
    AiOutlineAudit
} from "react-icons/ai";
import {
    FaDiscord,
} from "react-icons/fa";
import {
    GiBlackHandShield,
} from "react-icons/gi";
import {
    CgBee,
} from "react-icons/cg";
import {useAuthStore} from "../stores/auth";
import {RiZzzFill} from "react-icons/ri";
import {useMutation, useQuery} from "@tanstack/react-query";
import {apiClient} from "../api/base";
import {handlersQuery} from "../api/queries";

export type Agent = {
    uuid: string
    name: string
    isActive: boolean
    handler: string
    handlerUuid: string
    role: string
    timezones: string
    tags: string[]
    lastContact?: Date
    lastUpdate?: Date
    contactEmail: string
    contactDiscord: string
    corp: string
    alliance: string
    notes: string
}

export default function AgentDetailsComponent({agent, handleRemove}: { agent: Agent, handleRemove: () => void }) {
    const [role] = useAuthStore((state) => [state.role]);

    const [isEditing, setEditing] = useState(false);
    const [isActive, setIsActive] = useState(agent.isActive);
    const [handlerUuid, setHandlerUuid] = useState(agent.handlerUuid);
    const [handler, setHandler] = useState(agent.handler);
    const [agentRole, setAgentRole] = useState(agent.role);
    const [timezones, setTimezones] = useState(agent.timezones);
    const [contactEmail, setContactEmail] = useState(agent.contactEmail);
    const [contactDiscord, setContactDiscord] = useState(agent.contactDiscord);
    const [lastContacted, setLastContacted] = useState(agent.lastContact);

    const iconBgColor = useColorModeValue('green.100', 'green.900')
    const relativeTimeFormatter = new Intl.RelativeTimeFormat('en', {numeric: 'auto'});
    const currentDate = new Date()
    const inactiveTextColor = useColorModeValue('gray.400', 'gray.600')
    const badgeColor = useColorModeValue('gray.50', 'gray.800')

    const saveAgentDetailsQuery = useMutation({
        mutationFn: (agent: Agent) => {
            // POST request using fetch inside useEffect React hook
            const requestOptions = {
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    HandlerUuid: agent.handlerUuid,
                    Role: agent.role,
                    IsActive: agent.isActive,
                    ContactDetailsEmail: agent.contactEmail,
                    ContactDetailsDiscord: agent.contactDiscord,
                    Timezones: agent.timezones,
                })
            };

            return (apiClient
                .post(`api/agents/${agent.uuid}`, requestOptions)
                .json());
        },
    })

    const saveAgentLastContactedQuery = useMutation({
        mutationFn: (agent: Agent) => {
            return (apiClient
                .post(`api/agents/${agent.uuid}/last-contacted`)
                .json());
        },
    })

    function markContacted() {
        agent.lastContact = new Date()
        setLastContacted(agent.lastContact)
        saveAgentLastContactedQuery.mutate(agent)
    }

    function save() {
        agent.timezones = timezones
        agent.handlerUuid = handlerUuid
        agent.handler = handler
        agent.role = agentRole
        agent.contactEmail = contactEmail
        agent.contactDiscord = contactDiscord
        agent.isActive = isActive
        agent.lastUpdate = new Date()
        setEditing(false)

        saveAgentDetailsQuery.mutate(agent)
    }

    function cancel() {
        setTimezones(agent.timezones)
        setHandlerUuid(agent.handlerUuid)
        setHandler(agent.handler)
        setAgentRole(agent.role)
        setContactEmail(agent.contactEmail)
        setContactDiscord(agent.contactDiscord)
        setEditing(false)
    }

    let handlers: { "uuid": string, "name": string }[] = []

    const handlersQueryData = useQuery(handlersQuery(agent.handlerUuid, role === "master"));

    if (handlersQueryData.status === "success") {


        handlersQueryData.data.filter(elem => elem.Uuid !== agent.uuid).forEach((elem) => {

            handlers.push({
                uuid: elem.Uuid,
                name: elem.Name,
            })
        })

    } else if (handlersQueryData.status === "error") {
        console.error("Could not query handlers")
    }

    function setHandlerByUuid(handlerUuid: string, handlers: { "uuid": string, "name": string }[]) {
        setHandlerUuid(handlerUuid)
        setHandler(handlers.find(value => value.uuid === handlerUuid)?.name || "")
    }

    return (
        <Box
            maxW={'600px'}
            w={'350px'}
            bg={useColorModeValue('white', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            my={5}
            textAlign={'center'}>

            <Heading fontSize={'2xl'} fontFamily={'body'}>
                <Center>
                    <Flex alignItems={'top'}>
                        {agent.name}
                        {!isActive ? <RiZzzFill size={'15px'}/> : null}
                    </Flex>
                </Center>
            </Heading>
            <Text fontWeight={600} color={'gray.500'} mb={4}>
                {agent.corp ? agent.corp : "Corp Unknown"}, {agent.alliance ? agent.alliance : "Alliance Unknown"}
            </Text>

            <Stack align={'center'} justify={'center'} direction={'row'}>


                {isEditing && role === "master" ?
                    <Select value={agentRole} onChange={(event) => setAgentRole(event.target.value)}
                            variant='outline'>
                            <option value="spy">Spy</option>
                            <option value="handler">Handler</option>
                            <option value="master">Spymaster</option>
                    </Select>
                    :
                    <Badge
                        px={2}
                        py={1}
                        bg={badgeColor}
                        fontWeight={'400'}>
                        {agentRole.toUpperCase()}
                    </Badge>
                }

                {isEditing ?
                <WrapItem hidden={role !== "spy"}>
                    <Button size="sm"
                            onClick={() => {
                                setIsActive(!isActive);
                            }}
                            leftIcon={<RiZzzFill/>}>
                        {isActive ? "Deactivate" : "Reactivate"}
                    </Button>
                </WrapItem>
                    : ""}
            </Stack>

            <Stack
                spacing={4}
                pt={6}
                divider={
                    <StackDivider borderColor={useColorModeValue('gray.100', 'gray.700')}/>
                }>

                <Stack direction={'row'} align={'center'} alignItems={'center'}>
                    <Flex w={8} h={8} align={'center'} justify={'center'} rounded={'full'}
                          bg={useColorModeValue('purple.100', 'purple.900')}>
                        <Icon as={GiBlackHandShield} color={'purple.500'} w={5} h={5}/>
                    </Flex>

                    {isEditing && role === "master" ?
                        <Select value={handlerUuid} onChange={(event) => setHandlerByUuid(event.target.value, handlers)}
                                variant='outline' placeholder={'Assign handler'}>
                            {handlers.map((elem) => (
                                <option key={elem.uuid} value={elem.uuid}>{elem.name}</option>
                            ))}
                        </Select>
                        :
                        <Text color={agent.handler ? "" : inactiveTextColor} ml={2}
                              fontSize='lg'>{agent.handler ? agent.handler : "unassigned"}</Text>
                    }
                </Stack>

                <EditableFeature
                    icon={<Icon as={BiTimeFive} color={'green.500'} w={5} h={5}/>}
                    iconBg={iconBgColor}
                    isEditing={isEditing}
                    setValue={setTimezones}
                    value={timezones}
                    textColor={agent.timezones ? '' : inactiveTextColor}
                    text={agent.timezones}
                    placeholder={"05:00-20:00"}
                    postfix={'evetime'}
                    inputW={'160px'}
                />

                <Stack>
                    <Text fontWeight={600} color={'gray.500'}>
                        Contact Details
                    </Text>

                    <EditableFeature
                        icon={<Icon as={CgBee} color={'yellow.500'} w={5} h={5}/>}
                        iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                        isEditing={isEditing}
                        setValue={setContactEmail}
                        value={contactEmail}
                        textColor={agent.contactEmail ? '' : inactiveTextColor}
                        text={agent.contactEmail}
                    />
                    <EditableFeature
                        icon={<Icon as={FaDiscord} color={'purple.500'} w={5} h={5}/>}
                        iconBg={useColorModeValue('purple.100', 'purple.900')}
                        isEditing={isEditing}
                        setValue={setContactDiscord}
                        value={contactDiscord}
                        textColor={agent.contactDiscord ? '' : inactiveTextColor}
                        text={agent.contactDiscord}
                    />
                </Stack>


                <Stack direction={'row'} divider={
                    <StackDivider borderColor={useColorModeValue('gray.100', 'gray.700')}/>
                }>
                    {
                        // Stat: Last Contact
                    }
                    <Stat>
                        <StatLabel>Last contact</StatLabel>
                        <StatNumber fontWeight={'500'} fontSize='md'>
                            {role === "master" || role === "handler" ? <Wrap><WrapItem>
                                <IconButton
                                    onClick={() => markContacted()}
                                    isRound={true} variant='solid' fontSize='20px' size='sm'
                                    aria-label={"refresh last contacted"} icon={<BiRefresh/>}/>
                            </WrapItem><WrapItem pt={'4px'}>
                                {lastContacted ? relativeTimeFormatter.format(Math.round((lastContacted.getTime() - currentDate.getTime()) / 86400000), 'days') : "never"}
                            </WrapItem>
                            </Wrap> : (lastContacted ? relativeTimeFormatter.format(Math.round((lastContacted.getTime() - currentDate.getTime()) / 86400000), 'days') : "never")}
                        </StatNumber>
                    </Stat>

                    {
                        // Stat: Last Update
                    }
                    <Stat>
                        <StatLabel>Last update</StatLabel>
                        <StatNumber fontWeight={'500'} fontSize='md'>
                            {agent.lastUpdate ? relativeTimeFormatter.format(Math.round((agent.lastUpdate.getTime() - currentDate.getTime()) / 86400000), 'days') : "never"}
                        </StatNumber>
                    </Stat>
                </Stack>

                {isEditing ? (
                        <Wrap mt={4}>
                            <WrapItem>
                                <Button size="sm" onClick={() => save()}>
                                    Save
                                </Button>
                            </WrapItem>
                            <WrapItem>
                                <Button size="sm" onClick={() => cancel()}>
                                    Cancel
                                </Button>
                            </WrapItem>
                        </Wrap>
                    ) :
                    <>
                        <Stack spacing={4} direction={"row"}>
                            <Button alignSelf={"right"} leftIcon={<AiOutlineUserAdd/>}
                                    onClick={() => setEditing(true)}>
                                Edit Profile
                            </Button>

                            {role === "master" ?
                                <Button alignSelf={"right"} leftIcon={<AiOutlineAudit/>}>
                                    Audit Log
                                </Button> : null}
                        </Stack>
                    </>
                }

            </Stack>
        </Box>
    )
}

interface EditableFeatureProps {
    text: string
    value: string
    postfix?: string
    iconBg: string
    icon?: ReactElement
    inputW?: string
    isEditing: boolean
    placeholder?: string
    textColor?: string
    setValue: (value: string) => void
}

const EditableFeature = ({
                             value,
                             text,
                             postfix,
                             icon,
                             iconBg,
                             isEditing,
                             setValue,
                             inputW,
                             placeholder,
                             textColor
                         }: EditableFeatureProps) => {

    return (
        <Stack direction={'row'} align={'center'} alignItems={'center'}>
            <Flex w={8} h={8} align={'center'} justify={'center'} rounded={'full'} bg={iconBg}>
                {icon}
            </Flex>

            {isEditing ? (
                <Wrap>
                    <WrapItem>
                        <Input size="md" value={value} w={inputW}
                               onChange={(event) => {
                                   setValue(event.target.value)
                               }}
                               variant={"outline"} placeholder={placeholder}/>
                    </WrapItem>
                    <WrapItem>
                        <Center h='40px'><Text ml={2} fontSize='lg'>{postfix}</Text></Center>
                    </WrapItem>
                </Wrap>
            ) : <Text color={textColor} ml={2}
                      fontSize='lg'>{text ? text + (postfix ? " " + postfix : "") : "unknown"}</Text>}

        </Stack>
    )
}