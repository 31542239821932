import {Box, Button, Center, Text, useColorModeValue} from "@chakra-ui/react";
import React from "react";
import {useNavigate} from "react-router-dom";

export function UnauthenticatedPage() {
    const navigate = useNavigate();

    function handleClick() {
        navigate("/login/gice");
    }

    return (
        <Center>
            <Box
                maxW={'600px'}
                w={'600px'}
                bg={useColorModeValue('red.100', 'red.900')}
                boxShadow={'2xl'}
                rounded={'lg'}
                p={4}
                mt={6}
                textAlign={'center'}>
                <Text fontSize={"lg"}>You are not authenticated, or your authentication has expired.</Text>
                <Button mt={4} onClick={handleClick}>Login</Button>
            </Box>
        </Center>
    );
}
