import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuthStore} from "../stores/auth";
import {performGICELogin} from "../api/auth";
import {base64URL, randomURLValue, unBase64URL} from "../api/base";


export function GICELogin() {
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn());
    const GICE_URL = process.env.REACT_APP_GICE_URL;
    const GICE_CLIENT_ID = process.env.REACT_APP_GICE_CLIENT_ID;

    const lastLocation = useLocation().state;

    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            if (lastLocation) {
                navigate(lastLocation, {replace: true});
            } else {
                navigate("/", {replace: true});
            }
            return;
        }

        let abort = false;

        async function doLogin() {
            if (abort) return;
            const hashData = window.location.hash.slice(1);
            if (hashData.length) {
                const params = new URLSearchParams(window.location.hash.slice(1));

                const authCode = params.get("code");
                const authState = params.get("state");
                const storedAuthState = window.localStorage.getItem("storedState");
                const storedAuthNonce = window.localStorage.getItem("storedNonce");
                const storedVerifier = window.localStorage.getItem("storedVerifier");

                if (
                    authCode &&
                    authState &&
                    storedAuthState &&
                    storedAuthNonce &&
                    storedVerifier
                ) {
                    window.localStorage.removeItem("storedState");
                    window.localStorage.removeItem("storedNonce");
                    window.localStorage.removeItem("storedVerifier");
                    if (authState !== storedAuthState) throw new Error("bad auth state");

                    try {
                        await performGICELogin(authCode, storedVerifier, storedAuthNonce);
                    } catch {
                        throw new Error("failed to perform gice login");
                    }

                    const stateData = JSON.parse(unBase64URL(authState));

                    navigate(stateData.lastLoc ?? "/", {replace: true});
                }
            } else {
                const randomState = randomURLValue(48);
                const happyState = JSON.stringify({
                    randval: randomState,
                    lastLoc: lastLocation ?? undefined,
                });
                const encState = base64URL(happyState);

                const randomNonce = randomURLValue(48);
                const codeVerifier = randomURLValue(48);

                const encoder = new TextEncoder();
                const data = encoder.encode(codeVerifier);
                const digest = await window.crypto.subtle.digest("SHA-256", data);

                const typedDigest = new Uint8Array(digest);
                let outStr = "";
                for (const char of typedDigest) outStr += String.fromCharCode(char);
                const codeChallenge = base64URL(outStr);

                // console.debug("doLogin: URL: " + GICE_URL)
                // console.debug("doLogin: client_id: " + GICE_CLIENT_ID)
                // console.debug("doLogin: redirect_uri: " + window.location.href)

                // @ts-ignore
                const url = new URL(GICE_URL);
                // @ts-ignore
                url.searchParams.append("client_id", GICE_CLIENT_ID);
                url.searchParams.append("redirect_uri", window.location.href);
                url.searchParams.append("response_type", "code");
                url.searchParams.append("response_mode", "fragment");
                url.searchParams.append("state", encState);
                url.searchParams.append("nonce", randomNonce);
                url.searchParams.append("scope", "openid groups-limited");
                url.searchParams.append("code_challenge", codeChallenge);
                url.searchParams.append("code_challenge_method", "S256");

                if (abort) return;

                window.localStorage.setItem("storedState", encState);
                window.localStorage.setItem("storedNonce", randomNonce);
                window.localStorage.setItem("storedVerifier", codeVerifier);

                window.location.href = url.toString();
            }
        }

        doLogin();

        return () => {
            abort = true;
        };
    });

    return <>Redirecting...</>;
}
