import {Box, Button, Flex, Heading, Spacer} from "@chakra-ui/react";

import AgentDetailsComponent, {Agent} from "../components/AgentDetailsComponent";
import SpyIdentityComponent, {SpyIdentity} from "../components/SpyIdentityComponent";
import {AiOutlineUserAdd} from "react-icons/ai";
import AgentNoteComponent from "../components/AgentNoteComponent";
import {useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {agentDetailsQuery} from "../api/queries";
import {useParams} from "react-router-dom";
import {apiClient} from "../api/base";

export function AgentDetailsPage() {
    let {agentUuid} = useParams();

    const handleAgentRemove = () => {
    }

    const queryData = useQuery(agentDetailsQuery(agentUuid ? agentUuid : ""));

    if (!queryData.isSuccess) {
        return (<>Error</>)
    }

    const agent: Agent = {
        uuid: queryData.data.Uuid,
        name: queryData.data.Name,
        role: queryData.data.Role,
        isActive: queryData.data.IsActive,
        handler: queryData.data.HandlerName.Valid ? queryData.data.HandlerName.String : "",
        handlerUuid: queryData.data.HandlerUuid.Valid ? queryData.data.HandlerUuid.String : "",
        timezones: queryData.data.Timezones,
        tags: [queryData.data.Role],
        lastContact: queryData.data.LastContacted.Valid ? new Date(queryData.data.LastContacted.String) : undefined,
        lastUpdate: queryData.data.LastEdited.Valid ? new Date(queryData.data.LastEdited.String) : undefined,
        contactEmail: queryData.data.ContactDetailsEmail,
        contactDiscord: queryData.data.ContactDetailsDiscord,
        corp: queryData.data.Corp,
        alliance: queryData.data.Alliance,
        notes: queryData.data.Notes,
    }

    const spyIdentities = queryData.data.SpyIdentities.map(value => {
        return {
            "spyUuid": value.Uuid,
            "name": value.Name,
            "charCount": value.CharCount,
            "skillpoints": value.Skillpoints,
            "alliance": value.Alliance,
            "hasOmega": value.IsOmega,
            "tags": value.Tags
        }
    })

    return (
        <>
            <Flex gap={4} align={"top"}>
                <AgentDetailsComponent agent={agent} handleRemove={handleAgentRemove}/>
                <Box>
                    <SpyList agentUuid={agent.uuid} baseSpyIdentities={spyIdentities}/>
                    <Box
                        pl={3}
                        pr={3}
                        pt={5}
                        pb={5}
                    >
                        <Flex
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="flex-start"
                        >
                            <Box>
                                <Heading size="md" as="h2" fontFamily="heading">
                                    Notes
                                </Heading>
                            </Box>
                        </Flex>

                        <AgentNoteComponent agentUuid={agent.uuid} baseNotes={agent.notes}/>
                    </Box>
                </Box>
            </Flex>
        </>
    );
}

export function AddSpyButton({addSpyIdentity}: { addSpyIdentity: () => void }) {
    return (
        <Button alignSelf={"right"} leftIcon={<AiOutlineUserAdd/>} onClick={() => addSpyIdentity()}>
            Add Spy Identity
        </Button>
    )
}

export default function SpyList({baseSpyIdentities, agentUuid}: {
    baseSpyIdentities: SpyIdentity[], agentUuid: string
}) {
    const [spyIdentities, setSetSpyIdentities] = useState(baseSpyIdentities);

    const deleteSpyIdentityQuery = useMutation({
        mutationFn: (spyUuid: string) => {
            return (apiClient
                .delete(`api/agents/${agentUuid}/spy/${spyUuid}`)
                .json());
        },
    })

    const handleRemove = (spyUuid: string) => {
        setSetSpyIdentities((prevState) =>
            prevState.filter((person) => person.spyUuid !== spyUuid)
        );

        if (spyUuid != "") {
            deleteSpyIdentityQuery.mutate(spyUuid)
        }
    }

    // Adds a new SpyIdentity to the list
    const handleAddSpyIdentity = () => {
        setSetSpyIdentities((prevState) => [...prevState,
            {
                "spyUuid": "",
                "name": "",
                "charCount": 1,
                "skillpoints": 0,
                "alliance": "",
                "hasOmega": false,
                "tags": []
            },
        ])
    }

    return (
        <Box pl={3} pr={3} pt={5} pb={5} w={'700px'}>
            <Flex display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
                <Box>
                    <Heading size="md" as="h2" fontFamily="heading">
                        Spy Identities
                    </Heading>
                </Box>
                < Spacer/>
                <Box pl={4}>
                    <AddSpyButton addSpyIdentity={handleAddSpyIdentity}/>
                </Box>
            </Flex>

            {spyIdentities.map((spyIdentity, index) => (
                <SpyIdentityComponent key={index} agentUuid={agentUuid} spy={spyIdentity} handleRemove={handleRemove}/>
            ))}
        </Box>
    )
};

