import {
    Box,
    Button,
    Heading,
    IconButton,
    Image,
    Input, Select,
    Spacer,
    Tag,
    TagLabel,
    TagRightIcon,
    Text,
    useColorModeValue,
    Wrap,
    WrapItem
} from "@chakra-ui/react";

import React, {useState} from "react";
import {FaExchangeAlt, FaUserEdit} from "react-icons/fa";
import {BsFillPersonFill} from "react-icons/bs";
import {EditableTagsComponent} from "./EditableTagsComponent";
import {useMutation} from "@tanstack/react-query";
import {apiClient} from "../api/base";
import {v4 as uuidv4} from "uuid";

export interface SpyIdentity {
    spyUuid: string
    name: string
    hasOmega: boolean
    charCount: number
    skillpoints: number
    tags: Array<string>
    alliance: string
}

function skillPointsDisplay(skillpoints: number): string {
    switch (skillpoints) {
        case 20:
            return "<20M"
        case 50:
            return "<50M"
        case 100:
            return "50+M"
        default:
            return "<10M"
    }
}

export default function SpyIdentityComponent({spy, agentUuid, handleRemove}: {
    spy: SpyIdentity,
    agentUuid: string,
    handleRemove: (id: string) => void
}) {
    const [isEditing, setEditing] = useState(spy.spyUuid === "");

    const [name, setName] = useState(spy.name);
    const [alliance, setAlliance] = useState(spy.alliance);
    const [charCount, setCharCount] = useState(spy.charCount);
    const [skillpoints, setSkillpoints] = useState(spy.skillpoints);
    const [hasOmega, setHasOmega] = useState(spy.hasOmega);
    const [tags, setTags] = useState(spy.tags);

    const saveSpyIdentityQuery = useMutation({
        mutationFn: (spy: SpyIdentity) => {
            // POST request using fetch inside useEffect React hook
            const requestOptions = {
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    Name: spy.name,
                    Alliance: spy.alliance,
                    IsOmega: spy.hasOmega,
                    CharCount: spy.charCount,
                    Skillpoints: spy.skillpoints,
                    Tags: spy.tags,
                })
            };

            return (apiClient
                .post(`api/agents/${agentUuid}/spy/${spy.spyUuid}`, requestOptions)
                .json());
        },
    })

    function save() {
        if (spy.spyUuid == "") {
            spy.spyUuid = uuidv4().replaceAll("-","").toLowerCase()
        }
        spy.name = name
        spy.alliance = alliance
        spy.charCount = charCount
        spy.skillpoints = skillpoints
        spy.hasOmega = hasOmega
        spy.tags = tags
        setEditing(false)
        saveSpyIdentityQuery.mutate(spy)
    }

    function cancel() {
        setName(spy.name)
        setAlliance(spy.alliance)
        setCharCount(spy.charCount)
        setSkillpoints(spy.skillpoints)
        setHasOmega(spy.hasOmega)
        setTags(spy.tags)
        setEditing(false)
    }

    return (
        <Box
            borderRadius="lg"
            w={'full'}
            bg={useColorModeValue('white', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}

            mt={6}
            pl={3}
            pr={3}
            pt={1}
            pb={3}
        >
            <Wrap mt={4}>
                <WrapItem>
                    {isEditing ? (
                        <Input size="md" value={alliance} onChange={(event) => {
                            setAlliance(event.target.value)
                        }} variant={"outline"} placeholder={"Alliance"}/>
                    ) : <Heading size="md" as="h3" fontFamily="heading">{spy.alliance}</Heading>}
                </WrapItem>
                <WrapItem>
                    {isEditing ? (
                        <Input size="md" value={name} onChange={(event) => {
                            setName(event.target.value)
                        }} variant={"outline"} placeholder={"Personal Identifier"}/>
                    ) : <Text fontSize="md" pt='2px'>{spy.name}</Text>}
                </WrapItem>
                <Spacer/>

                {!isEditing ? (
                    <WrapItem>
                        <IconButton size="sm" aria-label='Edit' onClick={() => setEditing(true)} icon={<FaUserEdit/>}/>
                    </WrapItem>
                ) : null}

            </Wrap>

            <Wrap mt={4}>

                <WrapItem key="subscription">
                    <Tag size="md" variant="subtle" cursor={isEditing ? "pointer" : undefined}
                         onClick={isEditing ? () => setHasOmega(!hasOmega) : undefined}
                         colorScheme={hasOmega ? "orange" : ""} borderRadius="sm" fontSize="sm">
                        <TagLabel>{hasOmega ? "Omega" : "Alpha"}</TagLabel>
                        {isEditing ? (<TagRightIcon boxSize='14px' as={FaExchangeAlt}/>) : null}
                    </Tag>
                </WrapItem>

                <EditableTagsComponent isEditing={isEditing} tags={tags} setTags={setTags}/>

                <Spacer/>

                <WrapItem key="charcount">
                    <Tag size="md" variant="subtle" colorScheme="orange" borderRadius="sm" fontSize="sm">
                        {isEditing ? (
                            <Input size="sm" value={charCount} type={"number"} onChange={(event) => {
                                spy.charCount = Number(event.target.value);
                                setCharCount(Number(event.target.value));
                            }}
                                   htmlSize={1} width='auto' variant={"outline"} placeholder={"1"}/>
                        ) : <TagLabel>{spy.charCount}</TagLabel>}

                        <TagRightIcon ml={1} mr={0} as={BsFillPersonFill}/>
                    </Tag>
                </WrapItem>
                <WrapItem key="skillpoints">
                    <Tag size="md" variant="subtle" colorScheme="blue" borderRadius="sm" fontSize="sm">
                        {isEditing ? (
                            <Select value={skillpoints} onChange={(event) => {
                                spy.skillpoints = Number(event.target.value);
                                setSkillpoints(Number(event.target.value));
                            }} variant='outline'>
                                <option value="10">&lt;10M</option>
                                <option value="20">&lt;20M</option>
                                <option value="50">&lt;50M</option>
                                <option value="100">50+M</option>
                            </Select>
                        ) : <TagLabel>{skillPointsDisplay(spy.skillpoints)}</TagLabel>}

                        <Image ml={1} mr={-1} boxSize='20px' objectFit='cover' src={"/skillpoints.png"}/>
                    </Tag>
                </WrapItem>
            </Wrap>

            {isEditing ? (
                <Wrap mt={4}>
                    <WrapItem>
                        <Button size="sm" onClick={() => save()}>
                            Save
                        </Button>
                    </WrapItem>
                    <WrapItem>
                        <Button size="sm" onClick={() => cancel()}>
                            Cancel
                        </Button>
                    </WrapItem>
                    <WrapItem>
                        <Button size="sm" onClick={() => {
                            setEditing(false);
                            handleRemove(spy.spyUuid);
                        }
                        }>
                            Delete
                        </Button>
                    </WrapItem>
                </Wrap>

            ) : null}
        </Box>
    );
}
