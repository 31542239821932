import {
    Box,
    Heading,
    IconButton,
    Spacer,
    useColorModeValue,
    Wrap,
    WrapItem,
    Text,
    Input,
    Center, Textarea, Button
} from "@chakra-ui/react";
import {FaEdit} from "react-icons/fa";
import React, {useState} from "react";
import {SpyIdentity} from "./SpyIdentityComponent";
import {RiZzzFill} from "react-icons/ri";
import {useMutation} from "@tanstack/react-query";
import {apiClient} from "../api/base";
import {Agent} from "./AgentDetailsComponent";

export default function AgentNoteComponent({agentUuid, baseNotes}: {
    agentUuid: string,
    baseNotes: string,
}) {

    const [isEditing, setEditing] = useState(false);
    const [notes, setNotes] = useState(baseNotes);


    const saveAgentNotesQuery = useMutation({
        mutationFn: (notes: string) => {
            // POST request using fetch inside useEffect React hook
            const requestOptions = {
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    Notes:notes,
                })
            };

            return (apiClient
                .post(`api/agents/${agentUuid}/notes`, requestOptions)
                .json());
        },
    })

    function save() {
        baseNotes = notes
        setEditing(false)

        saveAgentNotesQuery.mutate(notes)
    }

    function cancel() {
        setNotes(baseNotes)
        setEditing(false)
    }
    return (
        <Box
            borderRadius="lg"
            w={'full'}
            bg={useColorModeValue('white', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}

            mt={3}
            pl={3}
            pr={3}
            pt={1}
            pb={3}
        >

            <Wrap mt={4}>
                <WrapItem>
                    <Heading size="md" as="h3" fontFamily="heading">
                        Goals
                    </Heading>
                </WrapItem>
                <Spacer/>
                <WrapItem>
                    <IconButton size={"sm"} aria-label='Edit' icon={<FaEdit/>}  onClick={() => setEditing(true)}/>
                </WrapItem>
            </Wrap>


            <Wrap mt={2}>
                {isEditing ? (
                            <Textarea size="md" value={notes}
                                   onChange={(event) => {
                                       setNotes(event.target.value)
                                   }}
                                   variant={"outline"} />

                ) :  <Text color={"gray.500"}>{notes}</Text> }
            </Wrap>

            {isEditing ? (
                    <Wrap mt={4}>
                        <WrapItem>
                            <Button size="sm" onClick={() => save()}>
                                Save
                            </Button>
                        </WrapItem>
                        <WrapItem>
                            <Button size="sm" onClick={() => cancel()}>
                                Cancel
                            </Button>
                        </WrapItem>
                        <Spacer/>
                    </Wrap>
                ) : null}
        </Box>
    );
}