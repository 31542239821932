import {QueryOptions} from "@tanstack/react-query";
import {apiClient} from "./base";

type APILastLoginResponse = {
    lastLogin: number;
};

type OptionalString = {
    String: string;
    Valid: boolean;
};

type APIQueryAgentsResponse = {
    agents: APIQueryAgentsResponseAgent[];
};

type APIQueryHandlersResponse = APIQueryHandlersResponseHandler[];


type APIQueryAgentsResponseAgent = {
    Uuid: string;
    Name: string;
    Role: string;
    HandlerName: OptionalString;
    LastContacted: OptionalString;
};

type APIQueryHandlersResponseHandler = {
    Uuid: string;
    Name: string;
};

type APIGetAgentDetailsResponse = {
    Uuid: string;
    Role: string;
    Name: string;
    IsActive: boolean;
    LastContacted: OptionalString;
    LastEdited: OptionalString;
    Corp: string;
    Alliance: string;
    HandlerUuid: OptionalString;
    HandlerName: OptionalString;
    ContactDetailsEmail: string;
    ContactDetailsDiscord: string;
    Timezones: string;
    Notes: string;
    SpyIdentities: APIGetAgentDetailsResponseSpyIdentity[];
};

type APIGetAgentDetailsResponseSpyIdentity = {
    Uuid: string;
    Name: string;
    Alliance: string;
    IsOmega: boolean;
    Role: string;
    CharCount: number;
    Skillpoints: number;
    Tags: string[];
};

export const lastLoginQuery: (
    userid: number
) => QueryOptions<APILastLoginResponse> = (userid: number) => ({
    queryKey: ["lastlogin", userid],
    queryFn: async () => {
        return (await apiClient
            .get(`api/last_login/${userid}`)
            .json()) as APILastLoginResponse;
    },
});


export const agentsQuery: (
    displayInactive: boolean,
    myAgentsOnly: boolean
) => QueryOptions<APIQueryAgentsResponse> = (displayInactive: boolean,
                                             myAgentsOnly: boolean) => ({
    queryKey: ["agents", displayInactive, myAgentsOnly],
    queryFn: async () => {
        return (await apiClient
            .get(`api/agents/?displayInactive=${displayInactive}&myAgentsOnly=${myAgentsOnly}`)
            .json()) as APIQueryAgentsResponse;
    },
});


export const handlersQuery: (
    currentHandlerUuid: string,
    enabled: boolean,
) => QueryOptions<APIQueryHandlersResponse> = (currentHandlerUuid: string, enabled: boolean) => ({
    queryKey: ["handlers", currentHandlerUuid],
    queryFn: async () => {
        return (await apiClient
            .get(`api/handlers/?currentHandlerUuid=${currentHandlerUuid}`)
            .json()) as APIQueryHandlersResponse;
    },
    enabled: enabled
});


export const agentDetailsQuery: (
    agentUuid: string
) => QueryOptions<APIGetAgentDetailsResponse> = (agentUuid: string) => ({
    queryKey: ["agentdetails", agentUuid],
    queryFn: async () => {
        return (await apiClient
            .get(`api/agents/${agentUuid}`)
            .json()) as APIGetAgentDetailsResponse;
    },
});
