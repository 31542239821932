import { useAuthStore } from "../stores/auth";
import { apiClient } from "./base";

interface APILoginToken {
    token: string;
}

export async function performGICELogin(
    code: string,
    verifier: string,
    nonce: string
) {
    const loginRequest = {
        nonce,
        verifier,
        code,
    };

    const response = (await apiClient
        .post("auth/gice", { json: loginRequest })
        .json()) as APILoginToken;

    useAuthStore.getState().setAuthToken(response.token);
}
