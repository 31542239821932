import * as React from "react"
import {ReactElement, useEffect} from "react"

import {
    Box,
    ChakraProvider,
    Flex,
    Grid,
    Heading,
    Icon,
    Stack,
    theme, useColorModeValue,
    VStack,
} from "@chakra-ui/react"

import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
    useLocation,
    useOutlet,
    useRouteError,
} from "react-router-dom";

import {ColorModeSwitcher} from "./ColorModeSwitcher"

import {Outlet} from "react-router-dom";

import {useAuthStore} from "./stores/auth";
import {HTTPError} from "ky";
import {GICELogin} from "./pages/LoginPage";
import {HelloPage} from "./pages/HelloPage";
import {GICELogout} from "./pages/LogoutPage";
import {AgentDetailsPage} from "./pages/AgentDetailsPage";
import AgentList from "./pages/AgentList";
import {GiBlackHandShield} from "react-icons/gi";
import {UnauthenticatedPage} from "./pages/UnauthenticatedPage";
import {NavBar} from "./components/Navigation";



function AuthenticatedRoute() {
    const loggedIn = useAuthStore((state) => state.isLoggedIn());
    const location = useLocation();
    const outlet = useOutlet()

    if (!loggedIn)
        return <Navigate to="/unauthenticated" replace={true} state={location}/>;

    return outlet;
}

// this is the top-level error handler (React Error Boundary)
// it's job is to handle any errors of "global" concern, like not being logged in
// and to do fallback rendering if an error happens that isn't handled somewhere else
function RootError() {
    const error = useRouteError();
    const authStore = useAuthStore();
    const location = useLocation();

    useEffect(() => {
        if (error instanceof HTTPError) {
            if (error.response.status === 401) {
                authStore.clearAuth()
            }
        }
    })

    if (error instanceof HTTPError) {
        if (error.response.status === 401) {
            return <Navigate to="/unauthenticated" replace={true} state={location}/>;
        } else if (error.response.status === 403) {
            return <>Forbidden</>;
        }
    }

    return <>{`${error}`}</>;
}

interface HeaderWithIconProps {
    text: string
    iconBg: string
    icon?: ReactElement
}

const HeaderWithIcon = ({text, icon, iconBg}: HeaderWithIconProps) => {
    return (
        <Stack direction={'row'} align={'center'} alignItems={'center'}>
            <Flex w={12} h={12} align={'center'} justify={'center'} rounded={'full'} bg={iconBg}>
                {icon}
            </Flex>
            <Heading>{text}</Heading>
        </Stack>
    )
}

export function App() {

    return (
        <ChakraProvider theme={theme}>
            <Box textAlign="left">
                <Grid p={3}>
                    <ColorModeSwitcher justifySelf="flex-end"/>
                    <VStack spacing={2}>

                        <HeaderWithIcon
                            icon={<Icon as={GiBlackHandShield} color={'purple.500'} w={8} h={8}/>}
                            iconBg={useColorModeValue('purple.900', 'purple.900')}
                            text={'Agent Tracking System'}
                        />

                        <Router>
                            <NavBar/>

                            <Routes>
                                <Route path="/" element={<RootLayout/>} errorElement={<RootError/>}>

                                    {/* special unprotected routes */}
                                    <Route path="/login/gice" element={<GICELogin/>}/>
                                    <Route path="/logout" element={<GICELogout/>}/>
                                    <Route path="/unauthenticated" element={<UnauthenticatedPage/>}/>

                                    <Route element={<AuthenticatedRoute/>}>
                                        <Route path="hello" element={<HelloPage/>}/>
                                        <Route path="agents" element={<AgentList/>}/>
                                        <Route path="agents/:agentUuid" element={<AgentDetailsPage/>}/>
                                    </Route>

                                    {/* navigate to the default page, from the index */}
                                    <Route path="/" element={<Navigate to="/hello" replace={true}/>}/>

                                    {/* and a catch-all route if nothing matched */}
                                    <Route path="*" element={<div style={{color: "red"}}>Not Found</div>}/>
                                </Route>
                            </Routes>
                        </Router>
                    </VStack>
                </Grid>
            </Box>
        </ChakraProvider>
    );
}

export function RootLayout() {
    return (
        <>
            <Outlet/>
        </>
    );
}
