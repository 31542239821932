import ky from "ky";
import { useAuthStore } from "../stores/auth";

// Authenticated HTTP client object
export const apiClient = ky.extend({
    credentials: "include",
    prefixUrl: process.env.REACT_APP_API_URL,
    hooks: {
        beforeRequest: [
            (request) => {
                const token = useAuthStore.getState().authToken;
                if (token) {
                    request.headers.set("Authorization", `Bearer ${token}`);
                }
            },
        ],
    },
});

// Return a random URL-friendly value
export function randomURLValue(len: number): string {
    const validChars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const randData = new Uint8Array(len);
    window.crypto.getRandomValues(randData);
    let array = Array.from(randData);
    array = array.map((x) => validChars.charCodeAt(x % validChars.length));
    return String.fromCharCode.apply(null, array);
}

// URL-friendly Base64
export function base64URL(s: string): string {
    return window
        .btoa(s)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
}

// URL-friendly un-Base64
export function unBase64URL(s: string): string {
    let ns = s.replace(/-/g, "+").replace(/_/g, "+");

    const pad = ns.length % 4;
    if (pad) {
        if (pad === 1) {
            throw new Error("invalid base64 string");
        } else {
            ns += new Array(5 - pad).join("=");
        }
    }
    return window.atob(ns);
}
