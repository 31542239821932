import * as React from "react";
import {createColumnHelper} from "@tanstack/react-table";
import {DataTable} from "../components/DataTable";
import {Box, Checkbox, Flex, Heading, Spacer} from "@chakra-ui/react";
import {Link as RouteLink} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {agentsQuery} from "../api/queries";
import {useState} from "react";
import {useAuthStore} from "../stores/auth";

type AgentItem = {
    uuid: string;
    name: string;
    handler: string;
    role: string;
    tags: string;
    alliances: string;
    daysSinceContact: number;
};

export default function AgentList() {
    const role = useAuthStore((s) => s.role);
    const [myAgentsOnly, setMyAgentsOnly] = useState(role !== "master");
    const [displayInactive, setDisplayInactive] = useState(false);

    const queryData = useQuery(agentsQuery(displayInactive, myAgentsOnly));

    let data: AgentItem[] = []

    if (queryData.status === "success") {
        const currentDate = new Date()

        queryData.data.agents?.forEach((agent) => {

            let contactedDate = agent.LastContacted.Valid ? new Date(agent.LastContacted.String) : new Date()

            data.push({
                uuid: agent.Uuid,
                name: agent.Name,
                handler: agent.HandlerName.Valid ? agent.HandlerName.String : "",
                role: agent.Role.charAt(0).toUpperCase() + agent.Role.slice(1),
                tags: "",
                alliances: "",
                daysSinceContact: agent.LastContacted.Valid ? Math.round((currentDate.getTime() - contactedDate.getTime()) / 86400000) : -1
            })
        })

    } else if (queryData.status === "error") {
        console.error("Could not query agents")
    }

    const columnHelper = createColumnHelper<AgentItem>();

    const columns = [
        columnHelper.accessor("name", {
            cell: (info) => <RouteLink to={"/agents/" + info.row.original.uuid}>{info.getValue()}</RouteLink>,
            header: "Agent"
        }),
        columnHelper.accessor("handler", {
            cell: (info) => info.getValue(),
            header: "Handler"
        }),
        columnHelper.accessor("role", {
            cell: (info) => info.getValue(),
            header: "Role"
        }),
        columnHelper.accessor("tags", {
            cell: (info) => info.getValue(),
            header: "Tags"
        }),
        columnHelper.accessor("alliances", {
            cell: (info) => info.getValue(),
            header: "Alliances"
        }),
        columnHelper.accessor("daysSinceContact", {
            cell: (info) => info.getValue() > -1 ? info.getValue() + " days ago" : "unknown",
            header: "Last Contact",
            meta: {
                isNumeric: true
            },
            enableColumnFilter: true
        })
    ];

    return (
        <Box>
            <Flex
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
            >
                <Box>
                    <Heading size="md" as="h2" fontFamily="heading">
                        List of Agents ({data.length})
                    </Heading>
                </Box>
                < Spacer/>
                {role === "master" ?
                <Box pl={4}>
                    <Checkbox
                      isChecked={myAgentsOnly}
                      onChange={(e) => setMyAgentsOnly(e.target.checked)}
                    > My Agents only</Checkbox>
                </Box>
                 : null}
                <Box pl={4}>
                    <Checkbox
                        isChecked={displayInactive}
                        onChange={(e) => setDisplayInactive(e.target.checked)}
                    > Display Inactive</Checkbox>
                </Box>
            </Flex>

            <DataTable columns={columns} data={data}/>
        </Box>
    );
}