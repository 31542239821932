import {LastLogin} from "../components/LastLogin";
import {useAuthStore} from "../stores/auth";
import {Box, Center, Text, useColorModeValue} from "@chakra-ui/react";
import React from "react";

export function HelloPage() {
    const username = useAuthStore((s) => s.username);
    const userid = useAuthStore((s) => s.userid);
    const role = useAuthStore((s) => s.role);

    const bgColor = useColorModeValue('white', 'gray.900')

    if (!userid) {
        return <>Not logged in</>;
    }

    const title = role === "master" ? "Spymaster" : (role === "handler" ? "Spyhandler" : "Spy")

    return (
        <Center>
            <Box
                maxW={'600px'}
                w={'600px'}
                bg={bgColor}
                boxShadow={'2xl'}
                rounded={'lg'}
                p={6}
                textAlign={'center'}>
                <Text fontSize={"lg"}>Greetings {title} {username}</Text>

                {bgColor === 'white' ?
                    <Text my={3}>... did you know that real spies always use dark mode?</Text>
                    : null}
                <LastLogin userid={userid}/>
            </Box>
        </Center>
    );
}
