import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAuthStore} from "../stores/auth";


export function GICELogout() {
    const authStore = useAuthStore();
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn());

    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            authStore.clearAuth()
        }

        navigate("/unauthenticated", {replace: true});
    });

    return <>Logging out...</>;
}
